.cohort-table {
    border-collapse: collapse;
    width: 100%;
    
  }
  
  .cohort-table th, .cohort-table td {
    border: 1px solid #ddd; /* Adjust border style and color as needed */
    padding: 8px;
    text-align: center;
    font-size: 10;
  }
  
  .cohort-table th {
    background-color: #f2f2f2; /* Optional: Add background color to header cells */
  }

  .heatmap-cell {
    text-align: center;
    font-weight: bold;
    color: white;
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  /* Customize the color scale by setting your desired start and stop colors */
  .heatmap-0 {
    background-color: #FFFFFF; /* Replace with your start color */
  }
  
  .heatmap-100 {
    background-color: #FF0000; /* Replace with your stop color */
  }